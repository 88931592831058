<template>
  <div>
    <b-row
      v-if="windowWidth >= 900"
    >
      <b-col lg="12">
        <b-card>
          <TradingViewWidgets :options="{ theme: isThemeDark ? 'dark' : 'light' }" />
        </b-card>
      </b-col>
    </b-row>

    <b-overlay
      :show="loadingOverlayCarteira"
      rounded="sm"
    >
      <b-row>
        <termo-de-uso />
        <b-col
          lg="12"
        >
          <b-card>
            <b-card-text
              v-if="carteiraAtualizacaoElapsed!=null"
              class="mb-0 d-flex justify-content-end align-items-center"
            >
              <b-button
                v-b-tooltip.hover.top="$t('spot.textTooltipAtuaizar')"
                class="btn-icon"
                variant="flat-secondary"
                pill
                @click="atualizarDados()"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                  size="18"
                />
              </b-button>
              <template v-if="errorLoadCarteira==null">
                {{ $t('spot.textLoadSuccessCarteira') }} {{ carteiraAtualizacaoElapsed }}
              </template>
              <template v-else>
                {{ $t('spot.textErroLoadCarteira') }}
              </template>
            </b-card-text>

            <b-card-body v-if="errorLoadCarteira==null">

              <b-row>
                <b-col
                  lg="7"
                >
                  <b-card
                    no-body
                    class="card-detach"
                  >
                    <b-card-body>
                      <b-card-title> {{ $t('spot.textValorEstimado') }}</b-card-title>
                      <b-row>
                        <b-col
                          v-if="estimativaUSD"
                          lg="4"
                        >
                          <div class="truncate">
                            <h2 class="font-large-1 font-weight-bolder p-1">
                              $ {{ estimativaUSD }}
                            </h2>
                          </div>
                        </b-col>
                        <b-col
                          v-if="windowWidth >= 900"
                          lg="6"
                        >
                          <vue-apex-charts
                            type="donut"
                            height="125"
                            :options="carteiraGrafico"
                            :series="seriesCarteira"
                          />
                        </b-col>
                      </b-row>
                    </b-card-body>
                  </b-card>
                </b-col>

                <b-col
                  v-if="creditoDesempenho"
                  lg="5"
                >
                  <b-card
                    no-body
                    class="card-detach"
                  >
                    <b-card-body class="mb-1 pb-3">
                      <b-card-title> {{ $t('spot.textCreditoTaxa') }}</b-card-title>
                      <h2 class="font-large-1 font-weight-bolder p-1">
                        $ {{ toFixed(creditoDesempenho.saldo, 2) }}
                      </h2>
                      <router-link
                        class="card-link"
                        :to="{ name: 'checkout-credito' }"
                      >
                        {{ $t('spot.textAdicionarSaldo') }}
                      </router-link>
                      <b-link
                        class="card-link"
                        :to="{ name: 'conta-credito-extrato'}"
                      >
                        {{ $t('spot.textExtrato') }}
                      </b-link>
                    </b-card-body>
                  </b-card>
                </b-col>
              </b-row>

            </b-card-body>
            <b-card-body v-else>
              {{ errorLoadCarteira }}
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>

    </b-overlay>

    <b-row>

      <b-col
        lg="6"
      >
        <b-overlay
          :show="loadingOverlaySinal"
          rounded="sm"
        >
          <b-card>
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  src="https://cryptoboot.com.br/repo/icons/usdt.png"
                  size="48px"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5><span class="font-weight-bolder">{{ parseFloat(totalUSDT).toFixed(6) }}</span> USDT</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t('spot.textSaldoDisponivel') }} {{ parseFloat(livreUSDT).toFixed(6) }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>

          <b-card>
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  src="https://cryptoboot.com.br/repo/icons/btc.png"
                  size="48px"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5><span class="font-weight-bolder">{{ parseFloat(totalBTC).toFixed(6) }}</span> BTC</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t('spot.textSaldoDisponivel') }} {{ parseFloat(livreBTC).toFixed(6) }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>

          <b-card>
            <b-media no-body>
              <b-media-aside
                class="mr-2"
              >
                <b-avatar
                  src="https://cryptoboot.com.br/repo/icons/bnb.png"
                  size="48px"
                />
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5><span class="font-weight-bolder">{{ parseFloat(totalBNB).toFixed(6) }}</span> BNB</h5>
                <b-card-text class="font-small-3 mb-0">
                  {{ $t('spot.textSaldoDisponivel') }} {{ parseFloat(livreBNB).toFixed(6) }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-overlay>

      </b-col>

      <b-col lg="6">
        <b-overlay
          :show="loadingOverlaySinal"
          rounded="sm"
        >
          <b-card :title="$t('spot.titleCardAssertividadeMensal')">
            <b-row>
              <b-col lg="9">
                <grafico-sinal
                  :resumo-sinal="resumoSinal"
                  :series="seriesSinal"
                />
              </b-col>
            </b-row>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>

    <b-row>
      <b-col lg="12">
        <b-card :title="$t('spot.titleTerceiroCard')">
          <b-card-body>
            <b-table
              ref="refListTable"
              :items="dataListUltimasVendas"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              :empty-text="$t('futures.emptyText')"
              class="font-weight-bold"
            >

              <template #cell(paridade)="data">
                <div class="text-nowrap py-1">
                  <b-avatar
                    size="md"
                    :src="getIcon(data.item.moedaTrade)"
                    style="background-color: transparent"
                  />
                  <span class="mx-1">
                    {{ data.item.moedaTrade }}<span class="font-weight-light">/{{ data.item.paridadeTrade }}</span>
                  </span>
                </div>
              </template>

              <template #cell(pnl)="data">
                <b-badge :variant="parseFloat(data.value) >=0 ? 'light-success' : 'light-danger'">
                  {{ toFixed(data.item.pnl,4) }} {{ data.item.paridadeTrade }}
                  <br>
                  <small>
                    ({{ getPercentDiff(data.item.totalCompra, data.item.total) }}%)
                  </small>
                </b-badge>
              </template>

              <template #cell(duracao)="data">
                {{ duracao(data.item.dataCriacaoCompra, data.item.dataExecucao) }}
              </template>

              <template #cell(dataCriacaoCompra)="data">
                {{ formatDateBR(data.value) }}
                as
                {{ formatHourBR(data.value) }}
              </template>

              <template #cell(agente)="data">
                {{ data.value }}
                <b-badge
                  variant="light-primary"
                  class="ml-1"
                >
                  #{{ data.item.sinalId }}
                </b-badge>
              </template>

            </b-table>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import {
  BCard, BLink, BTable, BBadge, BAvatar, BRow, BCol, BCardTitle, BOverlay, BCardHeader, BCardText, BCardBody, VBTooltip, BButton, BMedia, BMediaAside, BMediaBody,
} from 'bootstrap-vue'
import TradingViewWidgets from '@/layouts/components/custom/TradingViewWidgets.vue'
import VueApexCharts from 'vue-apexcharts'
import store from '@/store'
import utilsMixin from '@/mixins/utilsMixin'
import {
  formatHourBR, formatDateBR, formatDateTimeSemAnoBR, percentDiferenca, duracao, toIsoStringTimeZone,
} from '@/utils/filter'
import fechamentoStoreModule from './relatorio/fechamento/fechamentoStoreModule'
import sinalStoreModule from './relatorio/sinal/sinalStoreModule'
import usuarioStoreModule from './usuario/usuarioStoreModule'
import BoasVindasPopup from './usuario/BoasVindasPopup.vue'
import TermoDeUso from '../termos/TermoDeUso.vue'

const RELATORIO_FECHAMENTO_SPOT_STORE_MODULE_NAME = 'fechamento-relatorio-spot'
const SINAL_SPOT_STORE_MODULE_NAME = 'sinal-relatorio-spot'
const USUARIO_SPOT_STORE_MODULE_NAME = 'usuario-spot'

export default {
  components: {
    TradingViewWidgets,
    BCard,
    BLink,
    BTable,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BCardTitle,
    VueApexCharts,
    BOverlay,
    BoasVindasPopup,
    TermoDeUso,
    BCardHeader,
    BCardText,
    BCardBody,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
  },

  directives: {
    'b-tooltip': VBTooltip,
  },

  mixins: [utilsMixin],

  data() {
    return {
      errorLoadCarteira: null,
      loadingOverlayCarteira: false,
      loadingOverlaySinal: false,
      estimativaUSD: '',
      estimativaBTC: '',
      formatHourBR,
      formatDateBR,
      formatDateTimeSemAnoBR,
      percentDiferenca,
      duracao,
      dataListUltimasVendas: [],
      resumoSinal: [],
      seriesSinal: [],
      totalBtcCarteira: '',
      totalUSDTCarteira: '',
      atualizacaoCarteira: '',
      dataAtual: new Date(),
      seriesCarteira: [],
      labelsCarteira: [],
      totalBTC: 0,
      totalUSDT: 0,
			totalBNB: 0,
      livreUSDT: 0,
			livreBNB: 0,
      livreBTC: 0,
      creditoDesempenho: null,
    }
  },

  computed: {
    tableColumns() {
      return [
        { key: 'paridade', label: this.$i18n.t('spot.tableHeader.paridade'), tdClass: 'text-dark recentTransactionCell', },
        { key: 'totalCompra', label: this.$i18n.t('spot.tableHeader.totalCompra'), tdClass: 'text-dark recentTransactionCell', },
        { key: 'total', label: this.$i18n.t('spot.tableHeader.total'), tdClass: 'text-dark recentTransactionCell', },
        { key: 'pnl', label: this.$i18n.t('spot.tableHeader.pnl'), tdClass: 'text-dark recentTransactionCell text-center', thClass: 'text-center'} ,
        { key: 'dataCriacaoCompra', label: this.$i18n.t('spot.tableHeader.dataCriacaoCompra'), tdClass: 'text-dark recentTransactionCell text-center', thClass: 'text-center'},
        { key: 'duracao', label: this.$i18n.t('spot.tableHeader.duracao'), tdClass: 'text-dark recentTransactionCell'},
        { key: 'agente', label: this.$i18n.t('spot.tableHeader.trader'), tdClass: 'text-dark recentTransactionCell'},
      ]
    },

    carteiraAtualizacaoElapsed(){
      if(this.atualizacaoCarteira!=null) {
        return this.elapsedTime2(this.atualizacaoCarteira, this.dataAtual)
      }
      return null
    },

    isThemeDark() {
      return this.$store.state.appConfig.layout.skin === 'dark'
    },

    windowWidth() {
      return this.$store.state.app.windowWidth
    },

    carteiraGrafico() {
      let self = this
      const chartOptions = {
        noData: {
          text: 'Loading...',
        },
        labels: this.labelsCarteira,
        dataLabels: {
          enabled: false,
          formatter(val) {
            // eslint-disable-next-line radix
            return `${parseInt(val)}%`
          },
        },
        colors: ['#26A17B', '#F3BA2F', '#00e396', '#775dd0'],
        legend: {
          show: true,
          position: 'right',
          fontSize: '12px',
          fontFamily: 'Montserrat',
          formatter: function(seriesName, opts) {
            if (seriesName === 'USDT') {
              return ["<span class='font-weight-bolder'>"+seriesName+"</span>", "    ", parseFloat(self.totalUSDT).toFixed(6)]
            } else if (seriesName === 'BNB') {
              return ["<span class='font-weight-bolder'>"+seriesName+"</span>", "  ", parseFloat(self.totalBNB).toFixed(6)]

            } else if (seriesName === 'BTC') {
              return ["<span class='font-weight-bolder'>"+seriesName+"</span>", "  ", parseFloat(self.totalBTC).toFixed(6)]
            } else {
              return ["<span class='font-weight-bolder'>"+seriesName+"</span>", "  ", "$ " + parseFloat(opts.w.globals.series[opts.seriesIndex]).toFixed()]
            }
          }
        },
        chart: {
          type: 'donut'
        },
        plotOptions: {
          pie: {
            startAngle: -90,
            endAngle: 270,
            expandOnClick: false,
            donut: {
              labels: {
                show: false,
                name: {
                  fontSize: '12px',
                  fontFamily: 'Montserrat',
                },
                value: {
                  show: false,
                  fontSize: '12px',
                  formatter(val) {
                  // eslint-disable-next-line radix
                    return `${parseInt(val)}`
                  },
                },
                total: {
                  show: false,
                  fontSize: '12px',
                  label: 'Total',
                  formatter(val) {
                  // eslint-disable-next-line radix
                    return `${val.config.series[0]}`
                  },
                },
              },
            },
          },
        },
      }
      return chartOptions
    },
  },

  created() {
    if (!store.hasModule(USUARIO_SPOT_STORE_MODULE_NAME)) store.registerModule(USUARIO_SPOT_STORE_MODULE_NAME, usuarioStoreModule)
    this.isVerificaAssinaturaAtiva()
    if (!store.hasModule(SINAL_SPOT_STORE_MODULE_NAME)) store.registerModule(SINAL_SPOT_STORE_MODULE_NAME, sinalStoreModule)
    if (!store.hasModule(RELATORIO_FECHAMENTO_SPOT_STORE_MODULE_NAME)) store.registerModule(RELATORIO_FECHAMENTO_SPOT_STORE_MODULE_NAME, fechamentoStoreModule)
    this.pollDataAtual();
    this.getCarteira(true)
    this.getVendaResumo()
    this.getUltimasVendas()
  },

  beforeDestroy() {
    if (store.hasModule(SINAL_SPOT_STORE_MODULE_NAME)) store.unregisterModule(SINAL_SPOT_STORE_MODULE_NAME)
    if (store.hasModule(RELATORIO_FECHAMENTO_SPOT_STORE_MODULE_NAME)) store.unregisterModule(RELATORIO_FECHAMENTO_SPOT_STORE_MODULE_NAME)
    if (store.hasModule(USUARIO_SPOT_STORE_MODULE_NAME)) store.unregisterModule(USUARIO_SPOT_STORE_MODULE_NAME)
  },

  methods: {

    atualizarDados() {
      this.getCarteira(false)
      this.getUltimasVendas()
      this.getVendaResumo()
    },
    
    isVerificaAssinaturaAtiva() {
      store
        .dispatch('usuario-spot/isVerificaAssinaturaAtiva')
        .then(response => {
          if(response.data != true) {
            this.$router.push({ name: 'conta-assinatura' })
          }
        })
    },

    getUltimasVendas() {
      store
        .dispatch('fechamento-relatorio-spot/getUltimasVendas')
        .then(response => {
          this.dataListUltimasVendas = response.data.content
        })
    },

    getVendaResumo() {
      this.loadingOverlaySinal = true

      const currentDate = new Date()
      const primeiroDiaMes = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)
      const ultimoDiaMes = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0)

      const dataInicio = toIsoStringTimeZone(primeiroDiaMes)
      const dataFim = toIsoStringTimeZone(ultimoDiaMes)

      store
        .dispatch('sinal-relatorio-spot/getSinaisResumo', {
          dataInicio,
          dataFim,
        })
        .then(response => {
          this.seriesSinal = [response.data.totalAtivoPercent, response.data.totalGanhoPercent, response.data.totalPerdaPercent]
          this.resumoSinal = response.data
        }).finally(() => {
            this.loadingOverlaySinal = false
        });
    },

    getCarteira(cache) {
      this.loadingOverlayCarteira =  true

      store
        .dispatch('usuario-spot/getCarteira', cache)
        .then(response => {
          this.errorLoadCarteira = null,
          this.estimativaBTC = response.estimativaBTC
          this.estimativaUSD = response.estimativaUSD
          this.atualizacaoCarteira = response.atualizacao
          const seriesPrincipal = []
          const labelsPrincipal = []

          // const dataTop = response.data.carteira.filter(coin => coin.simbolo === 'BTC' || coin.simbolo === 'USDT' || coin.simbolo === 'BNB')
          const dataOutros = response.carteira.filter(coin => coin.simbolo !== 'BTC' && coin.simbolo !== 'USDT' && coin.simbolo !== 'BNB')

          const totalUSDTTemp = response.carteira.filter(coin => coin.simbolo === 'USDT')
          if (totalUSDTTemp != null && totalUSDTTemp.length > 0) {
            this.totalUSDT = parseFloat(parseFloat(totalUSDTTemp[0].disponivel) + parseFloat(totalUSDTTemp[0].bloqueado)).toFixed(2)
            this.livreUSDT = parseFloat(totalUSDTTemp[0].disponivel).toFixed(2)
            seriesPrincipal.push(parseFloat(parseFloat(totalUSDTTemp[0].totalUSD).toFixed(6)))
            labelsPrincipal.push(totalUSDTTemp[0].simbolo)
          } else {
            seriesPrincipal.push(parseFloat(0))
            labelsPrincipal.push('USDT')
          }

          const totalBTCTemp = response.carteira.filter(coin => coin.simbolo === 'BTC')
          if (totalBTCTemp != null && totalBTCTemp.length > 0) {
            this.totalBTC = parseFloat(totalBTCTemp[0].disponivel) + parseFloat(totalBTCTemp[0].bloqueado)
            this.livreBTC = totalBTCTemp[0].disponivel
            seriesPrincipal.push(parseFloat(parseFloat(totalBTCTemp[0].totalUSD).toFixed(6)))
            labelsPrincipal.push(totalBTCTemp[0].simbolo)
          } else {
            seriesPrincipal.push(parseFloat(0))
            labelsPrincipal.push('BTC')
          }

          const totalBNBTemp = response.carteira.filter(coin => coin.simbolo === 'BNB')
          if (totalBNBTemp != null && totalBNBTemp.length > 0) {
            this.totalBNB = parseFloat(totalBNBTemp[0].disponivel) + parseFloat(totalBNBTemp[0].bloqueado)
            this.livreBNB = parseFloat(totalBNBTemp[0].disponivel)
            seriesPrincipal.push(parseFloat(parseFloat(totalBNBTemp[0].totalUSD).toFixed(6)))
            labelsPrincipal.push(totalBNBTemp[0].simbolo)
          } else {
            seriesPrincipal.push(parseFloat(0))
            labelsPrincipal.push('BNB')
          }

          if (dataOutros != null) {
            let somaOutros = 0
            dataOutros.forEach(value => {
              somaOutros += parseFloat(value.totalUSD)
            })
            seriesPrincipal.push(parseFloat(somaOutros))
            labelsPrincipal.push('Outros')
          }

          this.seriesCarteira = seriesPrincipal
          this.labelsCarteira = labelsPrincipal

        }).catch((e) => {
          this.errorLoadCarteira = e.data
        }).finally(() => {
          this.loadingOverlayCarteira = false
        });
      store
      .dispatch('usuario-spot/getCreditoDesempenho', cache)
      .then(response => {
        this.creditoDesempenho = response.data
      }).catch(() => {
        this.creditoDesempenho = null
      })
    },

    pollDataAtual() {
      const self = this
      this.polling = setInterval(() => {
        self.dataAtual = new Date()
      }, 10000)
    },

  },
}
</script>
<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';
@import '~@core/scss/vue/libs/chart-apex.scss';

.dark-layout {
  div ::v-deep {
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
  .card-detach {
    background-color: $theme-dark-border-color !important;
  }
}

.card-detach {
  background-color: #f6f6f6 !important;
}

.mt-gap {
  margin-top: 28px;
}
.mb-gap {
  margin-bottom: 28px;
}

.earnings {
  background: #111727;
  border-radius: 0.428rem;

  &-overall {
    border-radius: 8px;
    background: #313A49;
    padding: 18px 22px;
    margin: 20px;
    color: #FFF;
    h4  {
      color: #FFF;
      font-size: 18px;
    }
    p {
      color: #FFF;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
    }
    legend {
      color: #FFF;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    small {
      color:  #BABFC7;
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
    }
    .donut-graph {
      pointer-events: none;
    }
  }
}

</style>

<style lang="scss">
  .recentTransactionCell {
    color: #101828 !important;
  }
</style>