import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getVendas(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('ordem-venda', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getVendasResumo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('ordem-venda/resumo', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getUltimasVendas() {
      return new Promise((resolve, reject) => {
        axios.get('/ordem-venda/fechada/ultimas')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getVendasExport(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('ordem-venda/export/excel', { params: queryParams, responseType: 'blob' })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getAgentes() {
      return new Promise((resolve, reject) => {
        axios.get('meu-agente')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
