import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {

    getSinais(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('sinal', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getSinaisResumo(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('sinal/resumo', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

    getAgentes() {
      return new Promise((resolve, reject) => {
        axios.get('meu-agente')
          .then(response => resolve(response))
          .catch(error => { reject(error) })
      })
    },

  },
}
